import {
  purchaseConfirmation,
  purchaseConfirmation2,
  purchaseCredit,
  purchaseFix,
  purchaseLogin,
  purchaseWithoutLogin
} from '@middlewares/purchase';

export default [
  {
    path: 'purchase/login',
    name: 'purchase.login',
    meta: {
      middleware: purchaseLogin
    },
    component: () => import('@pages/purchase/PurchaseLogin')
  },
  {
    path: 'purchase',
    name: 'purchase',
    meta: {
      middleware: purchaseWithoutLogin
    },
    component: () => import('@pages/purchase/Index')
  },
  {
    path: 'purchase/attention',
    name: 'purchase.attention',
    component: () => import('@pages/purchase/PurchaseAttention')
  },
  {
    path: 'purchase/confirmation',
    name: 'purchase.confirmation',
    meta: {
      middleware: purchaseConfirmation
    },
    component: () => import('@pages/purchase/PurchaseConfirmation')
  },
  {
    path: 'purchase/confirmation2',
    name: 'purchase.confirmation2',
    meta: {
      middleware: purchaseConfirmation2
    },
    component: () => import('@pages/purchase/PurchaseConfirmation2')
  },
  {
    path: 'purchase/credit',
    name: 'purchase.credit',
    meta: {
      middleware: purchaseCredit
    },
    component: () => import('@pages/purchase/PurchaseCredit')
  },
  {
    path: 'purchase/fix',
    name: 'purchase.fix',
    meta: {
      middleware: purchaseFix
    },
    component: () => import('@pages/purchase/PurchaseFix')
  },
  {
    path: 'purchase/3dsecure-callback',
    name: 'purchase.3dsecure-callback',
    component: () => import('@pages/purchase/Purchase3DSecureCallback')
  }
]
